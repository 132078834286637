<template>
    <div class="container mt-2">
        <EditProductModal v-bind:product="editProduct" v-if="showEditProductModal" @close="rejectPromise"
                          @confirm="resolvePromise"/>
        <h5 class="display-6">{{ filteredList.length }} Produkte</h5>
        <div class="d-flex justify-content-between">
            <div>
                <input type="text" class="form-control mb-2" v-model="search" placeholder="Produkte durchsuchen"/>
            </div>
            <div>
                <button class="btn btn-outline-success"
                        v-on:click="editProduct = {name: null,default_count: null,category_id: null,img_url: null}; editOrCreateProduct()">
                    <i class="bi bi-plus"></i>
                    Neues Produkt
                </button>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table table-sm">
                <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Stk</th>
                    <th class="d-none d-md-table-cell" scope="col">Bild</th>
                    <th scope="col">Kategorie</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody v-for="product in filteredList" :key="product.uid">
                <tr>
                    <td>{{ product.name }}</td>
                    <td>{{ product.default_count }}</td>
                    <td class="d-none d-md-table-cell">{{ product.img_url ? 'Ja' : 'Nein' }}</td>
                    <td>{{ product.category_name }}</td>
                    <td>
                        <!--<button class="btn" v-on:click="deleteProduct(product.uid)"><i class="bi bi-trash"></i></button>-->
                        <button class="btn" v-on:click="editProduct = product; editOrCreateProduct();"><i
                                class="bi bi-pencil-square"></i></button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>
</template>

<script>
import EditProductModal from "@/components/modals/EditProductModal";

export default {
    name: "Products",
    components: {EditProductModal},
    data: () => {
        return {
            search: "",
            editProduct: null,
            showEditProductModal: false,
            resolvePromise: null,
            rejectPromise: null
        }
    },
    computed: {
        products() {
            return this.$store.state.products;
        },
        filteredList() {
            return this.products.filter(product => {
                return product.name.toLowerCase().includes(this.search.toLowerCase())
            })
        }
    },
    methods: {
        editOrCreateProduct() {
            new Promise((resolve, reject) => {
                this.showEditProductModal = true;
                this.resolvePromise = param => resolve(param.product);
                this.rejectPromise = error => reject(error);

            }).then((product) => {
                if (product.uid) {
                    return this.updateProduct(product);
                } else {
                    return this.createProduct(product);
                }

            }, () => {/* rejected */
            }).finally(() => {
                this.showEditProductModal = false;
                this.editProduct = null;
            });
        },
        createProduct(newProduct) {
            return this.$store.dispatch("createProduct", newProduct)
                .then(() => {
                    this.newProduct = {
                        name: null,
                        default_count: null,
                        category_id: null,
                        img_url: null
                    };
                })
        },
        deleteProduct(prod_id) {
            return this.$store.dispatch("deleteProduct", {uid: prod_id});
        },
        updateProduct(product) {
            return this.$store.dispatch("updateProduct", product)
                .then(() => {
                    this.editProduct = null;
                })
        }
    },
    created() {
        this.$store.dispatch("loadProducts");
    }

}
</script>

<style scoped>

</style>