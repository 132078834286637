<template>

  <div>
    <h5>{{images.length}} Bilder</h5>

      <div class="d-flex flex-wrap">
          <b-pagination
                class="me-2"
                  v-model="currentPage"
                  :total-rows="images.length"
                  :per-page="perPage"
                  aria-controls="my-table">

          </b-pagination>

          <div class="me-2">
              <b-button v-b-modal.modal-1 variant="outline-primary">Hochladen</b-button>

              <b-modal id="modal-1" title="Produktbild hochladen">
                  <b-alert show :hidden="!message">{{message}}</b-alert>
                  <b-progress :value="progress" :max="maxProgress" :hidden="!uploading" show-progress animated></b-progress>
                  <input type="file" ref="productImage" @change="selectFile" />
                  <b-btn @click="uploadImages">Upload</b-btn>
              </b-modal>
          </div>

          <div>
              <b-button v-b-modal.modal-2 variant="success">
                  Bilder zuordnen
                  <b-badge v-if="imageSuggestions">{{imageSuggestions.length}} <span class="sr-only">Vorschläge</span></b-badge>
              </b-button>


              <b-modal id="modal-2" title="Produktbilder zuordnen">
                  <div v-for="(product, index) in imageSuggestions" :key="product.uid">
                      <b-card-group deck v-if="index === activeSuggestion">
                          <b-card no-body :header="'Vorschläge für ' + product.name">
                              <b-list-group flush v-for="suggestion in product.suggestions" :key="suggestion.name">
                                  <b-list-group-item href="#" @click="selectedImgUrl = suggestion.name;selectedProduct = product">{{suggestion.name}}</b-list-group-item>
                              </b-list-group>

                              <b-card-body>
                                  <div v-if="!selectedImgUrl">
                                      Klicke auf die Bildnamen.
                                  </div>
                                  <div v-if="selectedImgUrl">
                                      <div class="m-2 text-center">
                                          Produkt
                                          <b-badge class="bg-primary" variant="dark">{{product.name}}</b-badge>
                                          das Bild
                                          <b-badge class="bg-success" variant="success">{{ selectedImgUrl }}</b-badge>
                                          zuweisen?
                                      </div>
                                      <div class="m-2 mt-4 d-flex justify-content-between">
                                          <b-btn class="align-content-end" variant="outline-danger" @click="selectedImgUrl=null;selectedProduct=null">Nein</b-btn>
                                          <b-btn class="align-content-start" variant="outline-success" @click="setImageForProduct(product,selectedImgUrl)">Ja</b-btn>
                                      </div>
                                  </div>

                              </b-card-body>
                              <b-card-footer v-if="imageSuggestions.length>1">
                                  <b-btn block variant="outline-secondary" @click="nextSuggestion">Nächster Vorschlag</b-btn>
                              </b-card-footer>
                          </b-card>
                      </b-card-group>
                  </div>
              </b-modal>
          </div>
      </div>

      <b-table
              responsive
              striped
              :fields="fields"
              :items="images"
              :per-page="perPage"
              :current-page="currentPage">
          <template #cell(used)="data">
              {{ data.value ? 'Ja' : 'Nein' }}
          </template>
          <template #cell(thumb_url)="data">
            <b-link v-b-popover.click.blur.html.lefttop="thumbUrlTooltip(data.value)" variant="success" title="Vorschau">Vorschau</b-link>
          </template>
      </b-table>

      <b-btn :disabled="genDisabled" variant="danger" @click="generateAllThumbnails">Alle Thumbnails neu generieren</b-btn>

  </div>
</template>

<script>

import axios from 'axios';
import authHeader from "@/services/auth-header";
const API_URL = process.env.VUE_APP_API_URL + 'images';
export default {
name: "Images",
  data: () => {
    return {
        fields: [{
            key: "filename",
            label: "Bild",
            sortable: false
        },{
            key: "used",
            label: "Zugewiesen",
            sortable: false
        },{
            key: "thumb_url",
            label: ""
        }],
        perPage: 10,
        currentPage: 1,
        selectedFiles: undefined,
        currentFile: undefined,
        progress: 0,
        maxProgress: 100,
        uploading: false,
        message: "",
        activeSuggestion: 0,
        selectedImgUrl: null,
        selectedProduct: null,
        genDisabled: false,
        uploadSuccess: false
    }
  },
  computed: {
    images() {
      return this.$store.state.images;
    },
    imageSuggestions() {
        return this.$store.state.imageSuggestions;
    },
  },
  methods: {
    thumbUrlTooltip(url) {
      return '<img src="'+url+'" alt="Nicht da :(">';
    },
    setImageForProduct(product, imgUrl) {
        product.img_url = imgUrl;
        return this.$store.dispatch("updateProduct", product)
            .then(() => {
              this.imageSuggestions.splice(this.activeSuggestion, 1);
              this.nextSuggestion();
              this.selectedImgUrl = null;
            })
    },
    generateAllThumbnails() {
        this.genDisabled = true;
        let formData = new FormData();
        formData.set("command", "thumbnail");
        formData.set("override", "true");
        axios
            .post(API_URL, formData, {headers: authHeader()})
            .finally(() => {
                this.genDisabled = false;
            })
    },
    nextSuggestion() {
      if(this.imageSuggestions.length === 0 || this.activeSuggestion+1 === this.imageSuggestions.length) this.activeSuggestion = 0;
      else this.activeSuggestion++;
    },
    updateConfiguration(configuration) {
      this.$store.dispatch("updateConfiguration", configuration);
    },
    selectFile() {
      this.selectedFiles = this.$refs.productImage.files;
    },
    uploadImages() {
      this.uploading = true;
      this.progress = 0;
      this.currentFile = this.selectedFiles.item(0);
      this.message = "";
      let fnCallback = (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total);
      };
      this.$store.dispatch("uploadImages", {file: this.currentFile, callback: fnCallback}).then((data) => {
          this.uploading = false;
          this.message = data.messages.join(" ");
          this.uploadSuccess = data.success;
      }).catch(() => {
          this.uploading = false;
          this.message = "Fehler beim Upload. Bitte berücksichtige das nur JPG und PNG hochgeladen werden darf."
          this.uploadSuccess = false;
      });
    }
  },
    created() {
      this.$store.dispatch("loadImages");
      this.$store.dispatch("loadImageSuggestions");
    }
}
</script>

<style scoped>

</style>