import axios from 'axios';
import authHeader from "@/services/auth-header";

const API_URL = process.env.VUE_APP_API_URL + 'menu/';

class MenuService {

    addMenu(menu) {
        return axios
            .post(API_URL, menu, {headers: authHeader()})
            .then(response => {
                return response.data;
            });
    }

    updateMenu(menu) {
        return axios
            .put(API_URL + menu.uid, menu, {headers: authHeader()})
            .then(response => {
                return response.data;
            })
    }

    deleteMenu(menu) {
        return axios
            .delete(API_URL + menu.uid, {headers: authHeader()})
            .then(response => {
                return response.data;
            })
    }

    fetchAll() {
        return axios
            .get(API_URL, {headers: authHeader()})
            .then(response => {
                return response.data;
            })
    }
}

export default new MenuService();