<template>
  <fullscreen ref="fullscreen" background="#FFF" @change="fullscreenChange">
    <div>
      <ProductModal v-if="showProductModal" @close="showProductModal = false"/>
      <HistoryModal v-if="showHistoryModal" @close="showHistoryModal = false"/>
      <div class="container-fluid pb-5">
        <router-view />
      </div>
      <b-navbar class="navbar fixed-bottom navbar-light bg-light" toggleable="lg" v-if="loggedIn">

            <b-navbar-brand href="#"><img height="30px" alt="Wacholderhütte logo" class="d-inline-block align-bottom"
                                          src="./assets/logo_black.svg"> Backstube</b-navbar-brand>
          <b-collapse id="navbarNav" is-nav>
            <b-navbar-nav>
              <b-nav-item active-class="active" to="/">
                <i class="bi bi-archive"></i> Inventar
              </b-nav-item>
              <b-nav-item active-class="active" to="/products">
                <i class="bi bi-list-ol"></i>
                  Produkte
              </b-nav-item>
              <b-nav-item active-class="active" to="/settings">
                <i class="bi bi-sliders"></i>
                  Konfiguration
              </b-nav-item>
              <b-nav-item active-class="active" to="/stats">
                  <i class="bi bi-bar-chart-line"></i> Stats
              </b-nav-item>
              <b-nav-item active-class="active" to="/menu">
                <i class="bi bi-diagram-2"></i>
                  Menüs
              </b-nav-item>
              <!--
              <li class="nav-item">
                <router-link active-class="active" class="nav-link " to="/openingtimes"><i class="bi bi-clock"></i>
                  Öffnungszeiten
                </router-link>
              </li>
              -->
              <b-nav-item class="nav-item" to="/storyGenerator">
                <i class="bi bi-diagram-2"></i>
                  Story Generator
              </b-nav-item>
            </b-navbar-nav>
          </b-collapse>


          <div class="position-absolute bottom-0 end-0 mb-1">

            <span v-if="live && beam" title="Kuchen werden mit Stückzahlen Live übertragen" class="badge bg-danger">Live
              <div class="spinner-grow text-light spinner-grow-sm" role="status"></div>
            </span>
            <span v-if="!live && beam" title="Kuchen werden ohne Stückzahlen auf der Webseite angezeigt"
                  class="badge bg-info"><i class="bi bi-display"></i>
            </span>

            <a class="bi btn btn-lg bi-clock-history px-2" v-on:click="showHistoryModal=true"
               v-if="this.$router.currentRoute.name === 'home'"></a>
            <a class="bi btn btn-lg bi-basket px-2" v-on:click="showProductModal=true"
               v-if="this.$router.currentRoute.name === 'home'"></a>
            <a class="btn btn-lg bi px-2" @click="toggle"
               v-bind:class="{'bi-arrows-fullscreen':!fullscreen, 'bi-fullscreen-exit':fullscreen}"></a>

            <b-navbar-toggle target="navbarNav"></b-navbar-toggle>
          </div>

      </b-navbar>
    </div>
  </fullscreen>
</template>

<script>
import ProductModal from "@/components/modals/ProductModal";
import HistoryModal from "@/components/modals/HistoryModal";


export default {
  name: 'App',
  components: {
    HistoryModal,
    ProductModal
    //HelloWorld,
  },
  data: () => {
    return {
      showProductModal: false,
      showHistoryModal: false,
      configItems: [],
      fullscreen: false
    }
  },
  computed: {
    beam() {
      return this.$store.state.configuration.find(value => value.key === "enabled").value;
    },
    live() {
      return this.$store.state.configuration.find(value => value.key === "live").value;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  methods: {
    toggle() {
      this.$refs['fullscreen'].toggle() // recommended
      // this.fullscreen = !this.fullscreen // deprecated
    },
    fullscreenChange(fullscreen) {
      this.fullscreen = fullscreen
    },
    onConfigChanged() {

    },
    onConfigLoaded() {

    }
  },
  mounted() {
    this.$store.dispatch("loadConfiguration");
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;

}

.bakery-navbar-toggler {
  border: none;
}
</style>
