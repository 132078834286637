<template>
  <div>

    <div class="modal" id="qtyModal" tabindex="-1" style="display:block;">
      <div class="modal-dialog modal-fullscreen-md-down">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Öffnungszeiten Dialog</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    @click="$emit('close')"></button>
          </div>
          <div class="modal-body">
            <slot name="body" v-if="openingtime">

              <div class="mb-3">
                <label for="inputOpeningTimeTitle" class="form-label">Titel</label>
                <input type="text" v-model="openingtime.title" class="form-control" id="inputOpeningTimeTitle"
                       aria-describedby="titleHelp">
                <div id="titleHelp" class="form-text">Überschrift der Öffnungszeiten (z.B. Ostern)</div>
              </div>



            </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <button class="btn btn-danger modal-default-button" data-bs-dismiss="modal" @click="$emit('close')">
                Abbrechen
              </button>
              <button class="btn btn-success modal-default-button" data-bs-dismiss="modal"
                      @click="$emit('confirm', {openingtime: openingtime})">
                OK
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
export default {
  name: "EditOpeningTime",
  props: ["openingtime"],
  data: () => {
    return {}
  },
  methods: {

  },
  computed: {

  },
  created() {

  },
}
</script>

<style scoped>

</style>