<template>

  <div class="container mt-2">
    <h5>Statistik</h5>
    <div class="row mb-5">
      <div class="col">
        <h6>Verkäufe pro Kalenderwoche</h6>

        <div v-for="(item, index) in groupedByWeek" v-bind:key="index" >
          <div class="card m-2" style="width: 18rem;" v-if="item.sold > 0">
            <div class="card-body">
              <h5 class="card-title"></h5>

              <div class=" card-title d-flex justify-content-between">
                <span class="h5">{{ index }}</span>
                <div v-if="item.sold > 0">
                  <span class="h5">{{ item.sold }}</span>
                  <span>/{{ item.added }} <small style="color:red">{{item.added - item.sold}}</small></span>
                </div>
              </div>

              <div class="progress">
                <div class="progress-bar" role="progressbar" v-bind:style="'width: '+item.perc+'%'" aria-valuenow="25"
                     aria-valuemin="0" aria-valuemax="100">{{ item.perc }}%
                </div>
              </div>
              <p class="card-text">
              </p>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item" v-bind:key="cat.category" v-for="cat in item.data">
                <div class="d-flex justify-content-between">
                  <div>{{ cat.category }}</div>
                  <div><span class="badge bg-primary align-self-end">{{ cat.sold }} / {{ cat.added }}</span></div>
                </div>
              </li>

            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h6>Verkäufe pro Tag</h6>
        <table class="table">
      <thead>
        <tr>
          <th>Datum</th>
          <th>verkauft</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in sellsByWeekdays" :key="row.created">
          <td>{{row.date}}</td>
          <td>{{row.sold_sum}}</td>
        </tr>
      </tbody>
    </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Stats",
  data: () => {
    return {}
  },
  methods: {},
  computed: {
    sellsByWeek() {
      return this.$store.state.stats;
    },
    sellsByWeekdays() {
      return this.$store.state.statsDaily.filter(value => value.sold_sum > 0)
    },
    groupedByWeek() {

      let grouped = {};

      this.sellsByWeek.forEach((value) => {
        if (!grouped[value.week]) {
          grouped[value.week] = {data: [], sold: 0, added: 0, perc: 0};
        }
        grouped[value.week].data.push(value);
        grouped[value.week].sold += parseInt(value.sold_sum);
        grouped[value.week].added += parseInt(value.added_sum);
        grouped[value.week].perc = grouped[value.week].sold ? (Math.round(grouped[value.week].sold / grouped[value.week].added * 100)) : 0;

      });

      return grouped;
    }
  },
  created() {
    this.$store.dispatch("loadStats");
    this.$store.dispatch("loadStatsDaily");
  },
}
</script>

<style scoped>

</style>