import Vue from 'vue'
import Router from 'vue-router'
import Settings from "@/components/Settings";
import Inventory from "@/components/Inventory";
import Products from "@/components/Products";
import Stats from "@/components/Stats";
import Login from "@/components/Login";
import Menu from "@/components/Menu";
import StoryGenerator from "@/components/StoryGenerator";
import OpeningTimes from "@/components/OpeningTimes";

Vue.use(Router)

export const router = new Router({
    routes: [
        {
            path: '/',
            name: 'home',
            component: Inventory
            // lazy-loaded
            //component: () => import('./views/Profile.vue')
        },
        {
            path: '/products',
            name: 'products',
            component: Products
        },
        {
            path: '/settings',
            name: 'settings',
            component: Settings
        },
        {
            path: '/stats',
            name: 'stats',
            component: Stats
        },
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        {
            path: '/menu',
            name: 'menu',
            component: Menu
        },
        {
            path: '/storyGenerator',
            name: 'storyGenerator',
            component: StoryGenerator
        },
        {
            path: '/openingtimes',
            name: 'openingtimes',
            component: OpeningTimes
        }
    ]
});

router.beforeEach((to, from, next) => {

  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});

