<template>
  <div class="container">
    <Configuration v-on="$listeners" class="mt-3 mb-5"/>
    <Categories class="mb-5"/>
      <Images class="mt-3 mb-5" />
  </div>
</template>

<script>
import Categories from "@/components/Categories";
import Configuration from "@/components/Configuration";
import Images from "@/components/Images.vue";

export default {
name: "Settings",
  components: {Configuration, Categories, Images},
  methods: {
  }
}
</script>

<style scoped>

</style>