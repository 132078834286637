import Vue from 'vue'
import App from './App.vue'
import {router} from './router'
import store from "@/system/store";
import fullscreen from 'vue-fullscreen';
import VJsoneditor from 'v-jsoneditor'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

require('dotenv').config()

Vue.config.productionTip = false

import VeeValidate from 'vee-validate';
Vue.use(VeeValidate, { fieldsBagName: 'veeFields' });
Vue.use(fullscreen);
Vue.use(VJsoneditor);
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

new Vue({
  router,
  render: h => h(App),
  store
}).$mount('#app')
