<template>
  <div class="container mt-2">
    <div class="row">
      <div class="col">
        <ul class="list-group">
          <li class="list-group-item" aria-current="true" v-for="openingTime in openingTimes" :key="openingTime.uid">
          </li>
        </ul>
      </div>
    </div>

    <button class="btn btn-outline-success"
            v-on:click="editProduct = {name: null,default_count: null,category_id: null,img_url: null}; editOrCreateProduct()">
      <i class="bi bi-plus"></i>
      Neue Öffnungszeiten
    </button>

    <EditOpeningTime v-bind:openingtime="editOpeningTime" v-if="showEditOpeningTimeModal" @close="rejectPromise"
                     @confirm="resolvePromise"/>
  </div>
</template>

<script>
import EditOpeningTime from "@/components/modals/EditOpeningTimeModal";

export default {
  name: "OpeningTimes",
  components: {EditOpeningTime},
  data() {
    return {
      editOpeningTime: null,
      showEditOpeningTimeModal: false,
      resolvePromise: null,
      rejectPromise: null
    }
  },
  computed: {
    openingTimes() {
      return this.$store.state.openingtime.openingtimes;
    }
  },
  created() {
    return this.$store.dispatch("openingtime/loadOpeningTimes");
  },
  methods: {
    editOrCreateOpeningTime() {
      new Promise((resolve, reject) => {
        this.showEditProductModal = true;
        this.resolvePromise = param => resolve(param.product);
        this.rejectPromise = error => reject(error);

      }).then((product) => {
        if (product.uid) {
          return this.updateProduct(product);
        } else {
          return this.createProduct(product);
        }

      }, () => {/* rejected */
      }).finally(() => {
        this.showEditProductModal = false;
        this.editProduct = null;
      });
    },
    createOpeningTime(newOpeningTime) {
      return this.$store.dispatch("openingtime/createOpeningTime", newOpeningTime)
          .then(() => {
            this.editOpeningTime = {
              active_from: null,
              active_until: null,
              title: null,
              json_data: {},
              enabled: false
            };
          })
    },
    updateOpeningTime(product) {
      return this.$store.dispatch("openingtime/updateOpeningTimes", product)
          .then(() => {
            this.editProduct = null;
          })
    }
  }

}
</script>

<style scoped>

</style>