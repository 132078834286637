<template>
  <div>
    <AdvancedQuantityModal v-if="showQuantityModal" :item="editingItem" @close="rejectPromise" @confirm="resolvePromise" />
    <div v-if="inventory.length===0 && emptyItems.length===0" class="container">
      <div class="alert alert-info mt-5" role="alert">
        <h4 class="alert-heading">Inventar leer.</h4>
        <p>Füge über die Inventarverwaltung <i class="bi bi-basket"/> Prdukte zum Inventar hinzu.</p>
        <hr>
        <p class="mb-0">Im Konfigurationsmenü können Kategorien verwaltet und weitere Einstellungen vorgenommen werden. Zur Produktverwaltung geht's über einen separaten Menüpunkt.</p>
      </div>
    </div>
    <div v-if="inventory.length + emptyItems.length>0">
      <div class="d-flex justify-content-end mt-1 me-5">
        <span class="badge bg-secondary">Stücke: {{ count }} / {{countMax}}</span>
      </div>
      <ul class="list-group list-group-flush">

        <li class="list-group-item d-flex" v-for="item in inventory" :key="item.uid">
          <div class="d-flex-inline">
            <span class="badge" v-bind:class="{
              'bg-warning text-dark': (item.count_max / 2 > item.count) && item.count > 0,
              'bg-success': item.count_max / 2 <= item.count,
              'bg-danger': item.count < 1}">{{ item.count }} / {{ item.count_max }}</span>

          </div>

          <span class="d-flex-inline flex-grow-1 px-1 text-truncate">{{ item.name }}</span>

          <div class="d-flex-inline justify-content-end btn-group" role="group">
            <button type="button" v-on:click="updateItem(item,1, 'sell')" class="btn btn-sm px-3 btn-outline-success">1x</button>
            <button type="button" v-on:click="updateItem(item)" class="btn btn-sm btn-outline-secondary"><i class="bi bi-three-dots"></i></button>
          </div>
        </li>
        <li class="list-group-item d-flex" v-for="item in emptyItems" :key="item.uid">
          <div class="d-flex-inline">
            <span class="badge" v-bind:class="{
              'bg-warning text-dark': (item.count_max / 2 > item.count) && item.count > 0,
              'bg-success': item.count_max / 2 <= item.count,
              'bg-danger': item.count < 1}">{{ item.count }} / {{ item.count_max }}</span>

          </div>

          <span class="d-flex-inline flex-grow-1 px-1 text-truncate">{{ item.name }}</span>

          <div class="d-flex-inline justify-content-end btn-group" role="group">
            <button type="button" v-on:click="updateItem(item)" class="btn btn-sm btn-outline-secondary"><i class="bi bi-three-dots"></i></button>
          </div>
        </li>

      </ul>

    </div>
  </div>
</template>
<script>

import AdvancedQuantityModal from "@/components/modals/AdvancedQuantityModal";

export default {
  name: "Inventory",
  components: {AdvancedQuantityModal},
  computed: {
    inventory() {
      return this.$store.state.inventory.filter(value => value.count !== 0)
    },
    count() {
      return this.$store.state.inventory.map(i=>parseInt(i.count)).reduce((a,b)=>a+b, 0);
    },
    countMax() {
      return this.$store.state.inventory.map(i=>parseInt(i.count_max)).reduce((a,b)=>a+b, 0);
    },
    emptyItems() {
      return this.$store.state.inventory.filter(value => value.count === 0)
    }
  },
  data: () => {
    return {
      showQuantityModal: false,
      editingItem: null,
      quantityFromModal: 0,
      resolvePromise: null,
      rejectPromise: null,
      interval: null
    }
  },
  methods: {
    reload() {
      this.$store.dispatch("loadInventory");
    },
    updateItem(item, qty, endpoint='sell') {
      this.editingItem = item;

      new Promise((resolve, reject) => {
        if (!qty) {
          this.showQuantityModal = true;
          this.resolvePromise = param => resolve({ qty: param.qty, endpoint: param.endpoint});
          this.rejectPromise = error => reject(error);
        } else {
          resolve({qty, endpoint});
        }
      }).then(({qty, endpoint}) => {
        this.$store.dispatch("updateInventory", {uid: item.uid, endpoint: endpoint, data: {quantity: qty}});
      }, () => {/* rejected */}).finally(() => this.showQuantityModal = false);

      /*
      return fetch("http://api.bakery.wacholderhuette.de/inventory/" + item.uid + "/" + endpoint, {
          method: "PUT",
          body: JSON.stringify({quantity: qty}),
          headers: {
            "Content-Type": "application/json",
          }
        })
       */

    }
  },
  created() {
    this.reload()
  },
  mounted() {
    this.interval = setInterval(this.reload, 5000)
  },
  destroyed() {
    clearInterval(this.interval);
  }
}
</script>


<style scoped>

</style>