<template>
  <div>

    <div class="modal" id="qtyModal" tabindex="-1" style="display:block;">
      <div class="modal-dialog modal-fullscreen-md-down">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Produkt Dialog</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    @click="$emit('close')"></button>
          </div>
          <div class="modal-body">
            <slot name="body" v-if="product">
              <div class="text-center">
                  <img v-if="product.img_url" :src="thumbBaseUrl+product.img_url" width="177px" class="mt-2" :alt="product.img_url">
              </div>


              <div class="mb-3">
                <label for="inputProductName" class="form-label">Produktname</label>
                <input type="text" v-model="product.name" class="form-control" id="inputProductName"
                       aria-describedby="productNameHelp">
                <div id="productNameHelp" class="form-text">Name des Produkts (z.B. Streuselkuchen)</div>
              </div>

              <div class="mb-3">
                <label for="inputProductCategory" class="form-laberl">Kategorie</label>
                <select id="inputProductCategory" v-on:change="setDefaultCount()" class="form-select"
                        v-model="product.category_id">
                  <option v-for="category in categories" v-bind:value="category.uid" :key="category.uid">
                    {{ category.name }}
                  </option>
                </select>
              </div>

              <div class="mb-3">
                <label for="inputDefaultCount" class="form-label">Std. Stückzahl</label>
                <input type="number" class="form-control" id="inputDefaultCount" v-model="product.default_count"
                       aria-describedby="defaultCountHelp">
                <div id="defaultCountHelp" class="form-text">übliche Stückzahl des Produkts</div>
              </div>

              <div class="mb-3">
                <label for="inputProductUrl" class="form-label">Produktbild</label>
                <select id="inputProductUrl" v-on:change="setProductUrl" class="form-select" v-model="product.img_url">
                    <option v-for="img in images" v-bind:key="img.filename" v-bind:class="{'used': img.used}">
                        {{ img.filename }}
                    </option>
                </select>
              </div>

            </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <button class="btn btn-danger modal-default-button" data-bs-dismiss="modal" @click="$emit('close')">
                Abbrechen
              </button>
              <button class="btn btn-success modal-default-button" data-bs-dismiss="modal"
                      @click="$emit('confirm', {product: product})">
                OK
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show"></div>
  </div>
</template>

<script>

const API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "EditProductModal",
  props: ["product"],
  data: () => {
    return {}
  },
  methods: {
    setDefaultCount() {
      this.categories.forEach(value => {
        if (value.uid === this.product.category_id) {
          this.product.default_count = value.default_count;
        }
      })
    },
    setProductUrl() {

    }
  },
  computed: {
    categories() {
      return this.$store.state.categories;
    },
    images() {
        return this.$store.state.images;
    },
    thumbBaseUrl() {
        return API_URL+"thumbs/thumb_";
    }
  },
  created() {
    this.$store.dispatch("loadCategories");
    this.$store.dispatch("loadImages");
  },
}
</script>

<style scoped>
  .used { color: #ff0000;}
</style>