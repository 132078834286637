<template>
  <div class="container mt-2">
    <div class="row">
      <div class="col">
        <ul class="list-group">
          <li class="list-group-item" aria-current="true" v-for="menu in menus" :key="menu.uid">
            <div v-if="editMode !== menu.uid">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">{{ menu.title }}</h5>
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="menu.enabled"
                         v-on:change="updateMenu(menu)">
                  <label class="form-check-label" for="flexSwitchCheckDefault">Aktiviert</label>
                </div>
              </div>
              <p>{{ menu.description }}</p>
              <div v-for="item in menu.items" :key="item.title" class="mb-1">
                <div>{{ item.title }}
                  <i class="bi text-primary" :class="{'bi-eye':!item.hide,'bi-eye-slash':item.hide}"></i>
                  <i class="bi bi-image text-primary ps-1" v-if="item.img"></i>
                </div>
                <ul v-for="variation in item.variations" :key="variation">
                  <li>{{ variation }}</li>
                </ul>

              </div>

              <button class="btn btn-primary" v-on:click="editMode = menu.uid"><i class="bi bi-pencil"></i> Bearbeiten
              </button>
            </div>

            <div v-if="editMode === menu.uid">
              <div class="form-group mb-2">
                <label>
                  <input type="text" v-model="menu.title" class="form-control" placeholder="Titel"/>
                </label>
              </div>
              <div class="form-group mb-2">
                <label class="w-100">
                  <textarea class="form-control mb-2" rows="7" v-model="menu.description" placeholder="Beschreibung"/>
                </label>
              </div>
              <div class="form-group mb-2">
                <v-jsoneditor v-model="menu.items" :options="options" :plus="true" height="400px" @error="onError"/>
              </div>
              <button class="btn btn-primary" v-on:click="saveMenu(menu)"><i class="bi bi-pencil"></i> Speichern
              </button>
            </div>
          </li>
        </ul>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Menu",
  computed: {
    menus() {
      return this.$store.state.menu.menus;
    }
  },
  methods: {
    updateMenu(menu) {
      this.$store.dispatch('menu/updateMenu', menu);
    },
    saveMenu(menu) {
      this.updateMenu(menu);
      this.editMode = false;
    },
    onError() {

    }
  },
  created() {
    this.$store.dispatch("menu/loadMenus")
  },
  data() {
    return {
      editMode: false,
      options: {}
    }
  }
}
</script>

<style scoped>

</style>