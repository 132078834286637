<template>
  <div>

    <div class="modal" id="qtyModal" tabindex="-1" style="display:block;">
      <div class="modal-dialog modal-fullscreen-md-down">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{item.name}}</h5>
            <span class="badge mx-2" v-bind:class="{
              'bg-warning text-dark': (item.count_max / 2 > item.count) && item.count > 0,
              'bg-success': item.count_max / 2 <= item.count,
              'bg-danger': item.count < 1}">{{ item.count }} / {{ item.count_max }}</span>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$emit('close')"></button>
          </div>
          <div class="modal-body">
            <slot name="body">
              <div class="row">
                <div class="col text-center">
                  <div class="input-group mb-2">
                    <input type="text" class="form-control form-control-lg  text-center" v-model="qty">
                    <button class="btn btn-outline-secondary" type="button" v-on:click="delNumber()"><i class="bi bi-backspace-fill"></i></button>
                  </div>

                </div>
              </div>
              <div class="row row-cols-3 g-2 mb-2">
                <div class="col text-center" v-for="number in numbers" :key="number">
                  <button class="btn btn-lg btn-outline-secondary w-100" v-on:click="appendNumber(number)">{{ number }}
                  </button>
                </div>
                <div class="col text-center">
                  <button class="btn btn-lg btn-outline-secondary w-100" v-on:click="setNumber(item.count)">max
                  </button>
                </div>
                <div class="col text-center" >
                  <button class="btn btn-lg btn-outline-secondary w-100" v-on:click="appendNumber(0)">0
                  </button>
                </div>
                <div class="col text-center" >
                  <button class="btn btn-lg btn-outline-secondary w-100" v-on:click="clearNumber()"><i class="bi bi-x-circle-fill"></i>
                  </button>
                </div>
              </div>

              <button class="btn btn-success btn-lg w-100 mb-1" v-if="item.count>0" :class="{'disabled':qty===0 || qty > item.count}" @click="$emit('confirm', {qty: qty, endpoint: 'sell'})">
                Verkaufen
              </button>
              <div class="d-flex-inline justify-content-end btn-group w-100 mb-1" role="group">
                <button class="btn btn-lg btn-outline-secondary" :class="{'disabled':qty===0}" v-on:click="$emit('confirm', {qty: qty, endpoint: 'retoure'})"><i class="bi bi-arrow-left-right"></i> Retoure</button>
                <button class="btn btn-lg btn-outline-secondary" :class="{'disabled':qty===0}" v-on:click="$emit('confirm', {qty: qty, endpoint: 'restock'})"><i class="bi bi-arrow-clockwise"></i> Nachfüllen</button>
              </div>
              <div class="mb-3" v-if="item.count>0">
                <button class="btn btn btn-outline-secondary w-100 mb-1" :class="{'disabled':qty===0 || qty > item.count}" v-on:click="$emit('confirm', {qty: qty, endpoint: 'trash'})"><i class="bi bi-trash"></i> Entfernen</button>
                <div class="form-text text-center">bei verschenkten oder nicht verkaufbaren Stücken</div>
              </div>
              <div class="mb-3" v-if="item.count>0">
                <button class="btn btn btn-outline-danger w-100" :class="{'disabled':qty===0 || qty > item.count}" v-on:click="$emit('confirm', {qty: qty, endpoint: 'delete'})"><i class="bi bi-x-circle"></i> Löschen</button>
                <div class="form-text text-center">bei falsch hinzugefügten Stücken..</div>
              </div>
            </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <button class="btn btn-danger btn-sm modal-default-button w-100" data-bs-dismiss="modal" @click="$emit('close')">
                Abbrechen
              </button>

            </slot>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
export default {
  name: "AdvancedQuantityModal",
  data: () => {
    return {
      numbers: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      qty: 0,
      maxBefore: false
    }
  },
  props: {
    title: String,
    item: Object
  },
  methods: {
    appendNumber(number) {
      if(this.qty===0 || this.maxBefore === true) {
        this.qty = "";
        this.maxBefore = false;
      }
      this.qty = parseInt((this.qty + "") + number)

    },
    delNumber() {
      try {
        this.qty = (this.qty+"").slice(0, -1);
        if(this.qty==='') {
          this.qty = 0;
        }
      } catch {
        this.qty = 0;
      }
      this.maxBefore = false;
    },
    clearNumber() {
      this.qty = 0;
      this.maxBefore = false;
    },
    init() {
      this.qty = 0;
      this.maxBefore = false;
    },
    setNumber(number) {
      this.qty = number;
      this.maxBefore = true;
    },
  },
  mounted() {

  },
}
</script>

<style scoped>

</style>