import Vue from 'vue'
import Vuex from 'vuex'
import VueAxios from "vue-axios";
import axios from "axios";
import authHeader from "@/services/auth-header";
import {auth} from "@/system/auth.module";
import {menu} from "@/system/menu.module";
import {router} from "@/router";
import {openingtime} from "@/system/openingtimes.module";

Vue.use(Vuex);
Vue.use(VueAxios, axios)

Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL

const store = new Vuex.Store({
    modules: {
        auth: auth,
        menu: menu,
        openingtime: openingtime
    },
    state: {
        inventory: [],
        history: [],
        stats: [],
        statsDaily: [],
        products: [],
        categories: [],
        stories: [],
        configuration: [{key: "enabled", value: false}, {key: "live", value: false}],
        images: [],
        imageSuggestions: []
    },
    mutations: {
        SAVE_STORIES(state, stories) {
          Vue.set(state, "stories", [...stories]);
        },
        RESET_STORIES(state) {
            Vue.set(state, "stories", []);
        },
        SAVE_INVENTORY(state, inventory) {
            Vue.set(state, 'inventory', [...inventory]);
        },
        UPDATE_INVENTORY(state, payload) {
            const item = state.inventory.find(item => item.uid === payload.uid);
            Object.assign(item, payload);
        },
        SAVE_HISTORY(state, history) {
            Vue.set(state, 'history', [...history]);
        },
        SAVE_STATS(state, stats) {
            Vue.set(state, 'stats', [...stats]);
        },
        SAVE_STATS_DAILY(state, statsDaily) {
            Vue.set(state, 'statsDaily', [...statsDaily]);
        },
        SAVE_PRODUCTS(state, products) {
            Vue.set(state, 'products', [...products]);
        },
        SAVE_CATEGORIES(state, categories) {
            Vue.set(state, 'categories', [...categories]);
        },
        SAVE_IMAGES(state, images) {
            Vue.set(state, 'images', [...images]);
        },
        SAVE_IMAGE_SUGGESTIONS(state, imageSuggestions) {
            Vue.set(state, 'imageSuggestions', [...imageSuggestions]);
        },
        DELETE_IMAGE_SUGGESTION(state, imageSuggestion) {
            console.log(imageSuggestion);
            Vue.set(state, 'imageSuggestions', state.imageSuggestions);
        },
        SAVE_CONFIGURATION(state, configurationArray) {
            Vue.set(state, 'configuration', [...configurationArray]);
        },
        UPDATE_CONFIGURATION(state, configurationItem) {
            const item = state.configuration.find(item => item.key === configurationItem.key);
            Object.assign(item, configurationItem);
        },
        UPDATE_IMAGES(state, images) {
            Vue.set(state, 'images', [...images, ...state.images]);
        }
    },
    actions: {
        generateStories({commit}, payload) {
            commit("RESET_STORIES");
          return Vue.axios.post("generator", payload, {headers: authHeader()}).then(result => {
              commit("SAVE_STORIES", result.data);
          }).catch(error => {
              throw new Error(`Api ${error}`);
          })
        },
        loadInventory({commit}) {
            Vue.axios.get("inventory", {headers: authHeader()}).then(result => {
                commit("SAVE_INVENTORY", result.data);
            }).catch(error => {
                throw new Error(`Api ${error}`);
            })
        },
        loadHistory({commit}) {
            Vue.axios.get("history", {headers: authHeader()}).then(result => {
                commit("SAVE_HISTORY", result.data);
            }).catch(error => {
                throw new Error(`Api ${error}`);
            })
        },
        loadStats({commit}) {
            Vue.axios.get("history/sellsByWeek", {headers: authHeader()}).then(result => {
                commit("SAVE_STATS", result.data);
            }).catch(error => {
                throw new Error(`Api ${error}`);
            })
        },
        loadStatsDaily({commit}) {
            Vue.axios.get("history/sellsByWeekDay", {headers: authHeader()}).then(result => {
                commit("SAVE_STATS_DAILY", result.data);
            }).catch(error => {
                throw new Error(`Api ${error}`);
            })
        },
        loadProducts({commit}) {
            Vue.axios.get("product", {headers: authHeader()}).then(result => {
                commit("SAVE_PRODUCTS", result.data);
            }).catch(error => {
                throw new Error(`Api ${error}`);
            })
        },
        loadCategories({commit}) {
            Vue.axios.get("category", {headers: authHeader()}).then(result => {
                commit("SAVE_CATEGORIES", result.data);
            }).catch(error => {
                throw new Error(`Api ${error}`);
            })
        },
        loadImages({commit}) {
            Vue.axios.get("images", {headers: authHeader()}).then(result => {
                commit("SAVE_IMAGES", result.data);
            }).catch(error => {
                throw new Error(`Api ${error}`);
            })
        },
        loadImageSuggestions({commit}) {
            let formData = new FormData();
            formData.set("command","suggestions");
            return Vue.axios.post("images", formData,{headers: authHeader()}).then(result => {
                commit("SAVE_IMAGE_SUGGESTIONS", result.data);
            }).catch(error => {
                throw new Error(`Api ${error}`);
            })
        },
        loadConfiguration({commit}) {
            Vue.axios.get("configuration", {headers: authHeader()}).then(result => {
                commit("SAVE_CONFIGURATION", result.data);
            }).catch(error => {
                throw new Error(`Api ${error}`);
            })
        },
        undoHistory({commit}) {
            Vue.axios.get("history/undo", {headers: authHeader()}).then(result => {
                commit("SAVE_HISTORY", result.data);
            }).catch(error => {
                throw new Error(`Api ${error}`);
            });
        },
        updateInventory({commit}, {uid, endpoint, data}) {
            return Vue.axios.put("inventory/" + uid + "/" + endpoint, data, {headers: authHeader()}).then(result => {
                commit("UPDATE_INVENTORY", result.data)
            });
        },
        addToInventory({commit}, payload) {
            return Vue.axios.post("inventory", payload, {headers: authHeader()}).then(result => {
                commit("SAVE_INVENTORY", result.data)
            });
        },
        removeFromInventory({commit}, {uid}) {
            return Vue.axios.delete("inventory/" + uid, {headers: authHeader()}).then(result => {
                commit("SAVE_INVENTORY", result.data);
            });
        },
        // CRUD Product
        createProduct({commit}, newProduct) {
            return Vue.axios.post("product", newProduct, {headers: authHeader()}).then(result => {
                commit("SAVE_PRODUCTS", result.data);
            });
        },
        deleteProduct({commit}, {uid}) {
            return Vue.axios.delete("product/" + uid, {headers: authHeader()}).then(result => {
                commit("SAVE_PRODUCTS", result.data);
            });
        },
        updateProduct({commit}, product) {
            return Vue.axios.put("product/" + product.uid, product, {headers: authHeader()}).then(result => {
                commit("SAVE_PRODUCTS", result.data)
            });
        },
        // CRUD Category
        createCategory({commit}, newCategory) {
            return Vue.axios.post("category", newCategory, {headers: authHeader()}).then(result => {
                commit("SAVE_CATEGORIES", result.data);
            });
        },
        deleteCategory({commit}, {uid}) {
            return Vue.axios.delete("category/" + uid, {headers: authHeader()}).then(result => {
                commit("SAVE_CATEGORIES", result.data);
            });
        },
        updateCategory({commit}, category) {
            return Vue.axios.put("category/" + category.uid, category, {headers: authHeader()}).then(result => {
                commit("SAVE_CATEGORIES", result.data)
            });
        },
        // CRUD Configuration
        updateConfiguration({commit}, configuration) {
            return Vue.axios.put("configuration/" + configuration.key, configuration, {headers: authHeader()}).then(result => {
                commit("UPDATE_CONFIGURATION", result.data)
            });
        },
        // Upload
        uploadImages({commit}, {file, fnCallback}) {
            let formData = new FormData();
            formData.append("file",file);

            return Vue.axios({
                method: "POST",
                url: "images",
                data: formData,
                headers: { "Content-Type": "multipart/form-data", ...authHeader() },
                onUploadProgress: fnCallback
            }).then(result => {
                if(result.data.images) commit("UPDATE_IMAGES", result.data.images)
                return result.data;
            });
        }

    }
});

axios.interceptors.response.use(function (response) {
    return response
}, function (error) {

    if (error.response.status === 401) {
        store.dispatch("auth/logout");
        if (router.currentRoute.name !== "login") {
            router.push('/login')
        }
    }
    return Promise.reject(error)
})

export default store