import OpeningTimeService from '../services/openingtime.service';
import Vue from "vue";

export const openingtime = {
    namespaced: true,
    state: {
        openingtimes: []
    },
    actions: {
        createOpeningTime({commit}, openingTime) {
            return OpeningTimeService.addOpeningTime(openingTime).then(
                openingTimes => {
                    commit('createOpeningTime', openingTimes);
                    return Promise.resolve(openingTimes);
                }
            )
        },
        loadOpeningTimes({commit}) {
            return OpeningTimeService.fetchAll().then(
                openingTimes => {
                    commit('saveOpeningTimes', openingTimes);
                    return Promise.resolve(openingTimes);
                }
            )
        },
        updateOpeningTimes({commit}, openingTime) {
            return OpeningTimeService.updateMenu(openingTime).then(
                openingTime => {
                    commit('updateOpeningTime', openingTime);
                    return Promise.resolve(openingTime);
                }
            )
        }
    },
    mutations: {
        saveOpeningTimes(state, openingTimes) {
            Vue.set(state, 'openingtimes', [...openingTimes]);
        },
        updateOpeningTime(state, openingTime) {
            const item = state.openingtimes.find(item => item.uid === openingTime.uid);
            Object.assign(item, openingTime);
        },
        createOpeningTime(state, openingTimes) {
            //state.openingtimes.push(openingTime)
            Vue.set(state, 'openingtimes', [...openingTimes]);
        }
    }
};