<template>
  <div>
    <div class="modal" id="myModal" tabindex="-1" style="display: block">
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Historie <i class="bi bi-clock-history"></i></h5>

            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    @click="$emit('close')"></button>
          </div>
          <div class="modal-body">
            <slot name="body">
              <table class="table table-striped table-sm">
                <tbody>
                  <tr v-for="item in history" :key="item.uid" v-bind:class="{
                      'table-success': item.event === 'ITEM_SOLD',
                      'table-info': item.event === 'PRODUCT_ADD' || item.event === 'ITEM_ADD',
                      'table-warning': item.event === 'ITEM_RETOURE' || item.event === 'ITEM_REM',
                      'table-danger': item.event === 'PRODUCT_REMOVE' || item.event === 'ITEM_DEL'
                    }">
                    <td>{{ item.created }}</td>
                    <td>{{ translate(item.event, item.qty) }}</td>
                    <td>{{ item.name }}</td>
                  </tr>
                </tbody>
              </table>
            </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <button class="btn btn-md btn-warning modal-default-button" data-bs-dismiss="modal"
                      @click="undo()">
                <i class="bi bi-arrow-90deg-left"></i> Rückgängig
              </button>
              <button class="btn btn-md btn-success modal-default-button" data-bs-dismiss="modal"
                      @click="$emit('close')">
                OK
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
export default {
  name: "HistoryModal",
  data: () => {
    return {
    }
  },
  computed: {
    history() {
      return this.$store.state.history
    }
  },
  methods: {
    undo() {
      this.$store.dispatch("undoHistory");
      this.$store.dispatch("loadInventory");
    },
    translate(event, qty) {
      let pcs = qty +"x";

      switch (event) {
        case "ITEM_SOLD":
          return pcs +" verkauft";
        case "ITEM_ADD":
          return pcs +" hinzu";
        case "ITEM_RETOURE":
          return pcs +" Retoure";
        case "ITEM_DEL":
          return pcs +" entfernt";
        case "ITEM_REM":
          return pcs +" gelöscht";
        case "PRODUCT_ADD":
          return "Produkt hinzu";
        case "PRODUCT_REMOVE":
          return "Produkt entfernt";
        case "ITEM_REMOVED_FROM_INVENTORY":
          return pcs +" aus Inventar entfernt";
        default:
          return event;
      }
    }
  },
  created() {
    this.$store.dispatch("loadHistory");
  }
}
</script>

<style scoped>

</style>