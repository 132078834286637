import MenuService from '../services/menu.service';
import Vue from "vue";

export const menu = {
  namespaced: true,
  state: {
    menus: []
  },
  actions: {

    loadMenus({commit}) {
      return MenuService.fetchAll().then(
          menus => {
            commit('saveMenus', menus);
            return Promise.resolve(menus);
          }
      )
    },
    updateMenu({commit}, menu) {
      return MenuService.updateMenu(menu).then(
          menu => {
            commit('updateMenu', menu);
            return Promise.resolve(menu);
          }
      )
    }
  },
  mutations: {
    saveMenus(state, menus) {
      Vue.set(state, 'menus', [...menus]);
    },
    updateMenu(state, menu) {
      const item = state.menus.find(item => item.uid === menu.uid);
      Object.assign(item, menu);
    }
  }
};