<template>

  <div>
    <h5>Einstellungen</h5>
    <div v-for="configItem in configItems" :key="configItem.uid">
      <div class="form-check form-switch" v-if="configItem.type === 'boolean'">
        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="configItem.value" v-on:change="updateConfiguration(configItem)">
        <label class="form-check-label" for="flexSwitchCheckDefault">{{configItem.name}}</label>
        <div class="form-text">{{ configItem.description }}</div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
name: "Configuration",
  data: () => {
    return {
    }
  },
  computed: {
    configItems() {
      return this.$store.state.configuration;
    }
  },
  methods: {
    updateConfiguration(configuration) {
      this.$store.dispatch("updateConfiguration", configuration);
    }
  }
}
</script>

<style scoped>

</style>