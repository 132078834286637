<template>

  <div class="container mt-2">
       <h5>Instagram/Facebook-Story Bild generieren</h5>
    <div class="row mb-5">
      <div class="col">

        <button :disabled="loading" type="button" v-on:click="generate" class="btn btn-primary">Story-Bilder erstellen</button>
      </div>
    </div>
    <div class="row" v-show="loading">
      <div class="col">
        <div class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="!loading">
      <div class="col" v-for="img in stories" v-bind:key="img">
        <img :alt="img"  width="200px" :src="apiUrl+img+'?t='+Date.now()" />
      </div>
    </div>



  </div>
</template>

<script>
export default {
name: "StoryGenerator",
  data() {
    return {
      loading: false
    }
  },
  computed: {
    stories() {
      return this.$store.state.stories;
    },
    apiUrl() {
        return process.env.VUE_APP_API_URL;
    }
  },
  methods: {
    generate() {
      this.loading = true;
      this.$store.dispatch('generateStories').then(() => {
      }).finally(() => {
        this.loading = false;
      });
    }
  }
}
</script>

<style scoped>

</style>