<template>
  <div>
    <div class="modal" id="myModal" tabindex="-1" style="display: block">
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Inventarverwaltung <i class="bi bi-basket"></i></h5>

            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    @click="$emit('close')"></button>
          </div>
          <div class="modal-body" >
            <slot name="body">
                <div class="d-flex">
                    <input type="text" class="form-control mb-2 me-2 border-secondary" v-model="search" placeholder="Produkte durchsuchen"/>
                    <button type="button" class="btn btn-outline-secondary mb-2" v-bind:class="{active: showImages}" @click="showImages=!showImages" data-bs-toggle="button"><i class="bi bi-image"></i></button>
                </div>


              <div class="row row-cols-2 row-cols-xs-2 row-cols-sm-3 row-cols-md-5 row-cols-lg-6 g-2">
                <div class="col" v-for="item in filteredList" :key="item.uid">
                  <div class="card">
                    <div class="card-header text-center">
                      <span class="badge bg-success" v-if="item.inventory_id != null">im Inventar</span>
                      <span class="badge bg-danger" v-if="item.inventory_id == null"> nicht im Inventar</span>
                    </div>
                    <!--<img v-bind:src="item.img_url" class="card-img-top" alt="..."/>-->
                    <div class="card-body">
                      <img v-if="showImages" width="100%" :src="item.img_url?thumbBaseUrl+item.img_url:thumbBaseUrl+'placeholder.png'" alt="" />

                      <h5 class="card-title text-center text-truncate">{{ item.name }}</h5>

                      <div class="input-group mb-2">
                        <input type="number" class="form-control form-control-lg  text-center" v-model="item.default_count">
                        <a v-if="item.inventory_id != null" v-on:click="updateInInventory(item)" href="#"
                           class="btn btn-success"><i class="bi bi-plus-circle-dotted"></i></a>
                      </div>

                    </div>
                    <div class="card-footer">
                      <a v-if="item.inventory_id != null" v-on:click="removeFromInventory(item)" href="#"
                         class="btn btn-outline-danger w-100"> Entfernen</a>
                      <a v-if="item.inventory_id == null" v-on:click="addToInventory(item)" href="#"
                         class="btn btn-outline-success w-100"> Aufnehmen</a>
                    </div>
                  </div>
                </div>
              </div>
            </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <div class="btn-group" role="group" >
                <input type="radio" class="btn-check" name="btnradio" value="all" id="btnradio1" autocomplete="off" checked v-model="filterInventory">
                <label class="btn btn-md btn-outline-secondary" for="btnradio1">Alle</label>

                <input type="radio" class="btn-check" name="btnradio" value="in" id="btnradio2" autocomplete="off" v-model="filterInventory">
                <label class="btn btn-md  btn-outline-success" for="btnradio2">Im Inventar</label>

                <input type="radio" class="btn-check" name="btnradio" value="notin" id="btnradio3" autocomplete="off" v-model="filterInventory">
                <label class="btn btn-md  btn-outline-danger" for="btnradio3">Nicht im Inventar</label>
              </div>
              <button class="btn btn-md btn-success modal-default-button" data-bs-dismiss="modal" @click="$emit('close')">
                OK
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
const API_URL = process.env.VUE_APP_API_URL;
export default {
  name: "ProductModal",
  data: () => {
    return {
      filterInventory: "all",
      search: "",
      showImages: true,
    }
  },
  computed: {
    thumbBaseUrl() {
        return API_URL+"thumbs/thumb_";
    },
    products() {
      return this.$store.state.products
    },
    filteredList() {
      let invFilter = (product) => {
        if(this.filterInventory==="in") {
          return product.inventory_id != null;
        }
        else if(this.filterInventory==="notin") {
          return product.inventory_id == null;
        }
        return true;

      }

      return this.products
          .filter(invFilter)
          .filter(product => {
        return product.name.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  methods: {
    init() {
      this.$store.dispatch("loadProducts");
    },
    addToInventory(item) {
      return this.$store.dispatch("addToInventory", {product_id: item.uid, count: item.default_count, count_max: item.default_count}).then(() => this.init());
    },
    updateInInventory(item) {
      this.$store.dispatch( "updateInventory", {uid: item.inventory_id, endpoint: "restock", data: {quantity: item.default_count}}).then(() => this.init());
    },
    removeFromInventory(item) {
      this.$store.dispatch("removeFromInventory", {uid: item.inventory_id}).then(() => { this.init(); })
    }
  },
  created() {
    this.init();
  }
}
</script>

<style scoped>

</style>