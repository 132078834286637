import axios from 'axios';
import authHeader from "@/services/auth-header";

const API_URL = process.env.VUE_APP_API_URL + 'openingtime/';

class OpeningTimeService {

    addOpeningTime(openingTime) {
        return axios
            .post(API_URL, openingTime, {headers: authHeader()})
            .then(response => {
                return response.data;
            });
    }

    updateOpeningTime(openingTime) {
        return axios
            .put(API_URL + openingTime.uid, openingTime, {headers: authHeader()})
            .then(response => {
                return response.data;
            })
    }

    deleteOpeningTime(openingTime) {
        return axios
            .delete(API_URL + openingTime.uid, {headers: authHeader()})
            .then(response => {
                return response.data;
            })
    }

    fetchAll() {
        return axios
            .get(API_URL, {headers: authHeader()})
            .then(response => {
                return response.data;
            })
    }
}

export default new OpeningTimeService();