<template>
  <div>

    <div class="modal" id="qtyModal" tabindex="-1" style="display:block;">
      <div class="modal-dialog modal-fullscreen-md-down">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Kategorie Dialog</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$emit('close')"></button>
          </div>
          <div class="modal-body">
            <slot name="body" v-if="category">

                <div class="mb-3">
                  <label for="inputCategoryName" class="form-label">Kategoriename</label>
                  <input type="text" v-model="category.name" class="form-control" id="inputCategoryName"
                         aria-describedby="productNameHelp">
                  <div id="categoryNameHelp" class="form-text">Name des Kategorie (z.B. Trockenkuchen)</div>
                </div>

                <div class="mb-3">
                  <label for="inputDefaultCount" class="form-label">Std. Stückzahl</label>
                  <input type="number" class="form-control" id="inputDefaultCount" v-model="category.default_count" aria-describedby="defaultCountHelp">
                  <div id="defaultCountHelp" class="form-text">übliche Stückzahl der Kategorie</div>
                </div>

            </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <button class="btn btn-danger modal-default-button" data-bs-dismiss="modal" @click="$emit('close')">
                Abbrechen
              </button>
              <button class="btn btn-success modal-default-button" data-bs-dismiss="modal" @click="$emit('confirm', {category: category})">
                OK
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
export default {
  name: "EditCategoryModal",
  props: ["category"],
  data: () => {
    return {
    }
  },
  methods: {
  },
  mounted() {
  },
}
</script>

<style scoped>

</style>