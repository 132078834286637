<template>
  <div>
    <EditCategoryModal v-bind:category="editCategory" v-if="showEditCategoryModal" @close="rejectPromise"
                       @confirm="resolvePromise"/>
    <h5>Kategorien</h5>
    <div class="form-text">Hier können Produktkategorien angelegt, bearbeitet oder gelöscht werden.</div>
    <div class="table-responsive">
      <table class="table table-sm">
        <thead>
        <tr>
          <th scope="col">Art</th>
          <th scope="col">Stk</th>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody v-for="category in categories" :key="category.uid">
        <tr :key="category.uid">
          <td>{{ category.name }}</td>
          <td>{{ category.default_count }}</td>
          <td>
            <button class="btn" v-on:click="deleteCategory(category.uid)"><i class="bi bi-trash"></i></button>
            <button class="btn" v-on:click="editCategory = category; editOrCreateCategory()"><i
                class="bi bi-pencil-square"></i></button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <button class="btn btn-outline-success"
            v-on:click="editCategory = {name: null,default_count: null}; editOrCreateCategory();"><i
        class="bi bi-plus"></i>
      Kategorie hinzufügen
    </button>
  </div>
</template>

<script>
import EditCategoryModal from "@/components/modals/EditCategoryModal";

export default {
  name: "Categories",
  components: {EditCategoryModal},
  data: () => {
    return {
      editCategory: null,
      showEditCategoryModal: false,
    }
  },
  computed: {
    categories() {
      return this.$store.state.categories;
    }
  },
  methods: {
    editOrCreateCategory() {
      new Promise((resolve, reject) => {
        this.showEditCategoryModal = true;
        this.resolvePromise = param => resolve(param.category);
        this.rejectPromise = error => reject(error);

      }).then((category) => {
        if (category.uid) {
          return this.updateCategory(category);
        } else {
          return this.createCategory(category);
        }

      }, () => {/* rejected */
      }).finally(() => {
        this.showEditCategoryModal = false;
        this.editCategory = null;
      });
    },
    createCategory(newCategory) {
      this.$store.dispatch("createCategory", newCategory)
          .then(() => {
            this.newCategory = {
              name: null,
              default_count: null
            };
          })
    },
    updateCategory(category) {
      this.$store.dispatch("updateCategory", category)
          .then(() => {
            this.editCategory = null;
          })
    },
    deleteCategory(cat_id) {
      this.$store.dispatch("deleteCategory", {uid: cat_id});
    }
  },
  created() {
    this.$store.dispatch("loadCategories");
  }

}
</script>

<style scoped>

</style>